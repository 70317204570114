<template>
  <div>
    <slot />
  </div>
</template>

<script>
export default {
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

@mixin event-type-icon
  // MEMO イベント種別にオンラインイベントがないので現状はアイコンが不要
  // content: ' '
  width: 16px
  height: 13px
  display: inline-block
  margin-right: 6px

.event-main
  position: relative
  margin-bottom: 1.5rem
  padding-top: 1.5rem
  // MEMO: 主催者復活したら3.5remに戻す
  // margin-bottom: 3.5rem

  .event-cover
    display: flex
    justify-content: center
    align-items: center
    height: 300px
    background-size: contain
    background-position: center
    background-repeat: no-repeat
    background-image: linear-gradient(#f76b1c, #fa61ba)
    +app-mobile
      height: 200px
  .face-icon
    position: absolute
    bottom: -2.75rem
    left: 20px
    display: flex
    align-items: flex-end
    &::v-deep
      .name
        font-size: 0.75rem
        padding-bottom: 10px
        strong
          font-size: 1rem
.event-container
  .event-title
    font-size: 26px
    font-weight: bold
  .event-min-scheduled-at
    font-size: 20px
    font-weight: bold
  .event-type
    border-color: #333
    margin-bottom: 15px
    > *
      display: inline-flex
      align-items: center
    .event-type-fair
      &::before
        @include event-type-icon
        background: url('../images/event_types/fair.svg')
    .event-type-seminar
      &::before
        @include event-type-icon
        background: url('../images/event_types/seminar.svg')
    .event-type-parent_class
      &::before
        @include event-type-icon
        background: url('../images/event_types/parent_class.svg')
    .event-type-lab
      &::before
        @include event-type-icon
        background: url('../images/event_types/lab.svg')
    .event-type-local_meeting
      &::before
        @include event-type-icon
        background: url('../images/event_types/local_meeting.svg')
    .event-type-activity_meeting
      &::before
        @include event-type-icon
        background: url('../images/event_types/activity_meeting.svg')
    .event-type-introduction
      &::before
        @include event-type-icon
        background: url('../images/event_types/introduction.svg')
    .event-type-social_meeting
      &::before
        @include event-type-icon
        background: url('../images/event_types/social_meeting.svg')
    .event-type-childcare_experience
      &::before
        @include event-type-icon
        background: url('../images/event_types/childcare_experience.svg')
    .event-type-share_lab
      &::before
        @include event-type-icon
        background: url('../images/event_types/share_lab.svg')
    .event-type-study_meeting
      &::before
        @include event-type-icon
        background: url('../images/event_types/study_meeting.svg')
  .event-address
    font-size: 18x
    font-weight: bold
.schedule
  padding: 10px
  border-top: 1px solid #ddd
  &:last-child
    border-bottom: 1px solid #ddd

  .schedule__header
    color: #333
    font-size: 16px
    margin-bottom: 10px
  .schedule__header-open-range
    font-size: 20px
    font-weight: bold
  .schedule__header-subject
    font-weight: bold

  .tickets
    display: flex
    align-items: center
    margin-top: 6px
    margin-bottom: 12px
  .button.is-joined
    background-color: transparent
    border-color: #417505
    color: #417505
</style>
