<template>
  <button
    class="button is-primary is-rounded sharing-btn"
    :class="{'is-icons3over': iconImagesOver3}"
    @click="$emit('click')"
  >
    <span class="sharing-btn-text">
      シェア友をえらぶ
    </span>
    <i class="fa fa-arrow-right" />
    <span
      class="sharing-btn-icons"
      :class="{
        'icons-1': shortedIconImages.length === 1,
        'icons-2': shortedIconImages.length === 2,
        'icons-3': shortedIconImages.length === 3,
      }"
    >
      <FaceIcon
        v-for="(iconImage, key) in shortedIconImages"
        :key="key"
        class="is-x-small sharing-btn-icon"
        :src="iconImage"
      />
    </span>
    <span
      v-if="iconImagesOver3"
      class="sharing-btn-dots"
    >
      ...
    </span>
  </button>
</template>

<script>
import FaceIcon from 'components/FaceIcon.vue'

export default {
  components: { FaceIcon },
  props: {
    iconImages: {
      type: Array,
      required: true
    }
  },
  computed: {
    shortedIconImages () {
      return this.iconImages.slice(0, 3)
    },
    iconImagesOver3 () {
      return this.iconImages.length > 3
    }
  }
}
</script>

<style lang="sass" scoped>
@import '~stylesheets/resources'
.sharing-btn
  white-space: normal
  display: inline-flex
  align-items: center
  pading-left: 24px
  padding-right: 16px
  height: 60px
  font-size: 1rem
  line-height: 1
  font-weight: bold
  background-color: #ff7b00
  &.is-icons3over
    padding-right: 24px
  &::v-deep .face-icon.is-x-small
    width: 36px
    height: 36px
  &::v-deep .face-icon.is-x-small > .icon
    margin: 0
    width: 36px
    height: 36px
    border: 2px solid #ff7b00
  &:hover,
  &:active
    &::v-deep .face-icon.is-x-small > .icon
      border-color: #ff8f26
  .sharing-btn-text
    padding-right: 20px
  i
    padding-right: 6px
  .sharing-btn-icons
    position: relative
    height: 36px
    display: block
    justify-self: flex-end
    &.icons-1
      width: 36px
    &.icons-2
      width: 64px
    &.icons-3
      width: 92px

  .sharing-btn-icon
    width: 36px
    height: 36px
    &:nth-child(1)
      position: absolute
      left: 2px
    &:nth-child(2)
      position: absolute
      left: 30px
    &:nth-child(3)
      position: absolute
      left: 58px
  .sharing-btn-dots
    display: inline-block
    position: absolute
    right: 12px
    bottom: 12px
</style>
