<template>
  <div>
    <img
      :src="src"
      class="image-modal"
      @click.prevent.stop="handleClick"
    >
    <Portal to="modal">
      <!-- NOTE: スタイル属性に入るmax-widthを無効化している -->
      <BModal
        :active.sync="active"
        width=""
      >
        <p class="image">
          <img
            class="is-m-auto"
            :src="originalSrc"
            alt=""
          >
        </p>
      </BModal>
    </Portal>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true
    },
    originalSrc: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      active: false
    }
  },
  methods: {
    handleClick () {
      this.active = true
    }
  }
}
</script>

<style scoped lang="sass">
$_close-button-size: 32px
$_close-button-margin: 20px
$_close-button-x-padding: 7px
$_max-height: calc(100vh - ((#{$_close-button-margin} + #{$_close-button-size}) * 2))

.image-modal
  cursor: pointer
  border-radius: 5px

.modal
  &::v-deep .modal-content
    max-height: $_max-height
    max-width: calc(100% - ((#{$_close-button-margin} + #{$_close-button-x-padding}) * 2))
    width: auto

    .image
      img
        width: auto
        height: auto
        max-width: 100%
        max-height: $_max-height
</style>
