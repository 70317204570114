<template>
  <div>
    <a
      :href="linkPath"
      :data-method="isMessageRequired ? null : 'post'"
      class="is-primary-link"
      @click="onClick"
      v-text="label"
    />
    <Portal to="modal">
      <BModal
        :active.sync="isMessageModalActive"
        has-modal-card
      >
        <div class="modal-card">
          <header class="modal-card-head">
            棄却するユーザーへのメッセージを送る
          </header>
          <div class="modal-card-body">
            <p class="is-mb-3">
              ※棄却する相手に届きますので、理由を丁寧に記入下さい。
            </p>
            <form
              ref="messageForm"
              :action="path"
              method="post"
            >
              <input
                type="hidden"
                :name="csrfParam"
                :value="csrfToken"
              >
              <BField
                label="メッセージ"
                label-for="_organization_join_response_message"
                custom-class="required-label"
              >
                <BInput
                  id="_organization_join_response_message"
                  v-model="message"
                  name="organization_join_response[message]"
                  type="textarea"
                  placeholder="棄却する理由など"
                />
              </BField>
              <button
                class="button is-primary is-rounded"
                data-disable-with="送信中です..."
                v-text="label"
              />
            </form>
          </div>
        </div>
      </BModal>
    </Portal>
  </div>
</template>

<script>
import Rails from 'rails-ujs'

export default {
  props: {
    label: {
      type: String,
      required: true
    },
    path: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isMessageModalActive: false,
      message: ''
    }
  },
  computed: {
    csrfToken () {
      return Rails.csrfToken()
    },
    csrfParam () {
      return Rails.csrfParam()
    },
    isMessageRequired () {
      return this.path.endsWith('/deny')
    },
    linkPath () {
      return this.isMessageRequired ? '#' : this.path
    }
  },
  methods: {
    onClick (ev) {
      if (this.isMessageRequired) {
        ev.preventDefault()
        this.isMessageModalActive = true
      }
    }
  }
}
</script>

<style scoped lang="sass">
::v-deep
  @import '~stylesheets/components/required-label'
</style>
