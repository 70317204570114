<template>
  <BField
    :label="label"
    :message="errors"
    :type="status"
    :label-for="id"
    :custom-class="customClass"
  >
    <BInput
      :id="id"
      v-model="inputValue"
      :name="name"
    />
  </BField>
</template>

<script>
import * as inputs from './inputs'

function extractDate (text) {
  // 2018-10-02T14:46:37.766+09:00
  // ^^^^^^^^^^
  return (text || '').substr(0, 10)
}

export default {
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: ''
    },
    errors: {
      type: Array,
      default: () => []
    },
    isRequired: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return { inputValue: extractDate(this.value) }
  },
  computed: {
    status () {
      return inputs.status(this.errors)
    },
    id () {
      return inputs.idForName(this.name)
    },
    customClass () {
      return inputs.customClass(this.$props)
    }
  }
}
</script>

<style scoped lang="sass">
::v-deep
  @import '~stylesheets/components/required-label'
</style>
