<template>
  <div class="community-participation-user-wrapper">
    <div class="panel-block-content">
      <a :href="userShowPath">
        <div class="community-participation-user">
          <FaceIcon
            :src="user.avatarPath"
            class="user-icon"
          />
          <div class="user-text">
            <p class="user-name">
              <span v-text="user.fullName" />
              <span
                v-if="admin"
                class="tag is-primary is-ml-1"
              >
                コミュニティ管理者
              </span>
            </p>
            <p
              class="user-address is-size-7"
              v-text="addressAndDistance"
            />
          </div>
          <FriendGauge
            v-if="!isSelf"
            class="user-friend-gauge"
            :friend-level="friendshipLevel"
            :is-small="true"
          />
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import FaceIcon from 'components/FaceIcon.vue'
import FriendGauge from 'components/FriendGauge.vue'

export default {
  components: {
    FaceIcon,
    FriendGauge
  },
  props: {
    admin: {
      type: Boolean,
      required: true
    },
    levelByMe: {
      type: Number,
      required: true
    },
    levelByFriend: {
      type: Number,
      required: true
    },
    friendshipLevel: {
      type: Number,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    addressAndDistance: {
      type: String,
      required: true
    },
    isSelf: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    userShowPath () {
      return `/users/${this.user.id}`
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

$left-padding: 64px
$left-padding-sp: 48px
.community-participation-user-wrapper
  width: 100%
  margin: 10px
.community-participation-user
  display: flex
  justify-content: space-between
  width: 100%
  padding-left: $left-padding
  align-items: center
  position: relative
  +app-mobile
    padding-left: $left-padding-sp
  .user-text
    flex-grow: 1
    margin-left: 8px
  .user-name
    font-weight: bold
  .user-address
    color: #777
  .user-icon
    position: absolute
    top: 50%
    transform: translateY(-50%)
    left: 0
    display: block
    width: $left-padding
    +app-mobile
      width: $left-padding-sp
  .user-friend-gauge
    white-space: nowrap
::v-deep .face-icon
  display: block
::v-deep .face-icon > .icon
  display: block
  margin: 0
  &:first-child:not(:last-child)
    margin: 0
+app-mobile
  ::v-deep .face-icon > .icon
    width: 48px
    height: auto
</style>
