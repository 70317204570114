<template>
  <div class="recommend-neighbor">
    <div class="inner">
      <a
        v-for="(user, key) in users"
        :key="key"
        :href="`/users/${user.id}`"
        class="face"
      >
        <FaceIcon :src="user.avatarPath">
          <span
            class="fullname"
            v-text="user.fullName"
          />
        </FaceIcon>
      </a>
    </div>
  </div>
</template>

<script>
import FaceIcon from 'components/FaceIcon.vue'

export default {
  components: {
    FaceIcon
  },
  props: {
    users: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped lang="sass">
  .recommend-neighbor
    padding-top: 0
    padding-bottom: 0

    &::v-deep .face-icon
      font-weight: normal

    > .origtitle
      font-weight: bold
      font-size: 1.5rem

    > .inner
      padding-left: 10px
      padding-right: 10px
      padding-bottom: 0.6rem
      margin: 0.6rem 0 0
      overflow-x: auto
      white-space: nowrap

      &::-webkit-scrollbar
        height: 4px

      &::-webkit-scrollbar-thumb
        background-color: rgba(0, 0, 50, 0.25)
        border-radius: 10px

      > .face
        margin-right: 1rem
        font-size: 0.825rem
        font-weight: 700
      .fullname
        display: inline-block
</style>
