<template>
  <BField
    :label="label"
    :message="errors"
    :type="status"
    :label-for="id"
    :custom-class="customClass"
  >
    <BSelect
      :id="id"
      v-model="inputValue"
      :name="name"
    >
      <option
        v-for="opt in options"
        :key="opt.value"
        :value="opt.value"
        v-text="opt.label"
      />
    </BSelect>
  </BField>
</template>

<script>
import { parse, format } from 'date-fns'
import * as inputs from './inputs'

export default {
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: ''
    },
    errors: {
      type: Array,
      default: () => []
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    selectableMinutesDuration: {
      type: Number,
      default: 15
    },
    includeBlank: {
      type: Boolean,
      default: true
    }
  },
  data () {
    let datetime = parse(this.value)
    datetime = this.normalizeTime(datetime)
    return { inputValue: format(datetime, 'H:mm') }
  },
  computed: {
    status () {
      return inputs.status(this.errors)
    },
    id () {
      return inputs.idForName(this.name)
    },
    customClass () {
      return inputs.customClass(this.$props)
    },
    options () {
      const arr = []
      if (this.includeBlank) {
        arr.push({ value: '', label: '' })
      }
      for (let h = 0; h < 24; h += 1) {
        for (let m = 0; m < 60; m += this.selectableMinutesDuration) {
          const min = `00${m}`.slice(-2)
          const time = `${h}:${min}`
          arr.push({ value: time, label: time })
        }
      }
      return arr
    }
  },
  methods: {
    normalizeTime (date) {
      return inputs.normalizeTime(date, this.selectableMinutesDuration)
    }
  }
}
</script>

<style scoped lang="sass">
::v-deep
  @import '~stylesheets/components/required-label'
</style>
