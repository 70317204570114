<template>
  <div class="action-box">
    <a
      class="link"
      href="#"
      @click.prevent="onClick"
    >
      <p
        class="action-box-button"
        :class="{ 'is-helpee': isHelpee, 'is-helper': isHelper, 'topics-shares-page': topicsSharesPage }"
        v-text="buttonLabel"
      />
    </a>
    <Portal to="modal">
      <BModal
        :active.sync="isModalActive"
        :width="320"
      >
        <div
          class="modal-card"
          style="width: auto"
          :class="{ 'is-helpee': isHelpee, 'is-helper': isHelper }"
        >
          <header class="modal-card-head">
            <p
              class="modal-card-title"
              v-text="modalTitle"
            />
          </header>
          <section class="modal-card-body">
            <ActionBoxBody
              :action-type="actionType"
              :nav-paths="navPaths"
            />
          </section>
        </div>
      </BModal>
    </Portal>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: Array,
      required: true
    },
    navPaths: {
      type: Array,
      required: true
    },
    modalTitle: {
      type: String,
      required: true
    },
    buttonLabel: {
      type: String,
      required: true
    },
    onlyButton: {
      type: Boolean,
      default: false
    },
    actionType: {
      type: String,
      default: ''
    },
    topicsSharesPage: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isModalActive: false
    }
  },
  computed: {
    isHelpee () {
      return this.actionType === 'helpee'
    },
    isHelper () {
      return this.actionType === 'helper'
    }
  },
  methods: {
    onClick () {
      this.isModalActive = true
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

.action-box
  position: relative
  width: 185px
  +app-mobile
    position: absolute
    bottom: calc(10% + 34px)
    width: 40%
    &:last-child
      right: 5%

.action-box-button
  position: absolute
  top: 115px
  font-weight: bold
  text-align: center
  border-radius: 1.5em
  width: 185px
  padding: 5px
  color: #fff
  +app-mobile
    top: 0
    width: 100%

  br
    display: none
.topics-shares-page
  position: static

.modal-card-title
  text-align: center
  font-size: 16px
.modal-card-body
  border-bottom-left-radius: 6px
  border-bottom-right-radius: 6px
  padding: 0
  &::v-deep
    ul
      li
        a
          font-size: 20px

.is-helpee.action-box-button
  background: #5cac00

.is-helper.action-box-button
  background: #f2796e
</style>
