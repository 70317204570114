<template>
  <div class="embed-action-box">
    <div
      class="embed-action-box-title"
      v-text="modalTitle"
    />
    <ActionBoxBody
      :action-type="actionType"
      :nav-paths="navPaths"
      class="embed-action-box-body"
    />
  </div>
</template>

<script>
export default {
  props: {
    navPaths: {
      type: Array,
      required: true
    },
    modalTitle: {
      type: String,
      required: true
    },
    actionType: {
      type: String,
      default: ''
    }
  },
  computed: {
    isHelpee () {
      return this.actionType === 'helpee'
    },
    isHelper () {
      return this.actionType === 'helper'
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'
.embed-action-box
  margin: 0 12px
  +app-mobile
    margin: 0 2px
  border: 1px solid #ddd
  border-radius: 6px
  .embed-action-box-title
    text-align: center
    font-size: 12px
    border-bottom: 1px solid #ddd
    font-weight: normal
    padding: 8px
  .embed-action-box-body
    padding: 0
    font-size: 16px
  &::v-deep
    .action-box__list-item
      a
        padding-left: 20px
        padding-right: 20px
        +app-mobile
          padding: 10px
      i, i:nth-child(2)
        +app-mobile
          font-size: 16px
</style>
