import { render, staticRenderFns } from "./BaseRadioInput.vue?vue&type=template&id=44549f95&scoped=true&"
import script from "./BaseRadioInput.vue?vue&type=script&lang=js&"
export * from "./BaseRadioInput.vue?vue&type=script&lang=js&"
import style0 from "./BaseRadioInput.vue?vue&type=style&index=0&id=44549f95&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44549f95",
  null
  
)

export default component.exports