<template>
  <div class="event-form-container">
    <slot
      :onSubmit="onSubmit"
      :invisible="invisible"
      :onInvisibleChanged="onInvisibleChanged"
      :onChangeEventType="onChangeEventType"
      :isNotCommunityEvent="isNotCommunityEvent"
      :onChangeOnlineCheck="onChangeOnlineCheck"
      :isOffline="isOffline"
      :visibleFreeLabels="visibleFreeLabels"
      :onAddFreeLabel="onAddFreeLabel"
      :canAddMoreFreeLabel="canAddMoreFreeLabel"
      :onChangeWithoutGenerationFiltering="onChangeWithoutGenerationFiltering"
      :withoutGenerationFiltering="withoutGenerationFiltering"
      :onPreviewClicked="previewCard"
    />
    <Portal to="modal">
      <BModal
        :active.sync="showPreview"
        :width="320"
      >
        <p class="preview-notice">
          ※画像は一度保存してからプレビューしてください。
        </p>
        <EventListItem v-bind="eventListItemProps" />
      </BModal>
    </Portal>
  </div>
</template>

<script>
import axios from 'axios'
import autoInputLatlng from 'lib/auto_input_latlng'

export default {
  props: {
    defaultInvisible: {
      type: Boolean,
      required: true
    },
    defaultEventType: {
      type: String,
      default: ''
    },
    defaultIsOnline: {
      type: Boolean,
      required: true
    },
    defaultWithoutGenerationFiltering: {
      type: Boolean,
      default: false
    },
    freeLabels: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      invisible: this.defaultInvisible,
      eventType: this.defaultEventType,
      isOnline: this.defaultIsOnline,
      withoutGenerationFiltering: this.defaultWithoutGenerationFiltering,
      visibleFreeLabels: this.initialVisibleFreeLabels(),
      showPreview: false,
      eventListItemProps: {}
    }
  },
  computed: {
    isNotCommunityEvent () {
      return this.eventType !== 'community'
    },
    isOffline () {
      return !this.isOnline
    },
    canAddMoreFreeLabel () {
      return this.visibleFreeLabels.length < this.freeLabels.length
    }
  },
  methods: {
    async previewCard () {
      const params = new FormData(this.$el.querySelector('form'))
      params.set('_method', 'post')
      const { data } = await axios.post('/events/preview.json', params)
      this.eventListItemProps = data
      this.showPreview = true
    },
    async onSubmit () {
      await autoInputLatlng()
      this.$el.querySelector('form').submit()
    },
    onInvisibleChanged (checked) {
      this.invisible = checked
    },
    onChangeEventType (v) {
      this.eventType = v
    },
    onChangeOnlineCheck (checked) {
      this.isOnline = checked
    },
    onChangeWithoutGenerationFiltering (checked) {
      this.withoutGenerationFiltering = checked
    },
    onAddFreeLabel () {
      const next = this.visibleFreeLabels.length
      if (next < this.freeLabels.length) {
        this.visibleFreeLabels.push(this.freeLabels[next])
      }
    },
    initialVisibleFreeLabels () {
      let lastIndex = -1
      for (let i = this.freeLabels.length - 1; i >= 0; --i) {
        if (this.freeLabels[i].value) {
          lastIndex = i
          break
        }
      }
      const visibles = []
      for (let i = 0; i <= lastIndex; ++i) {
        visibles.push(this.freeLabels[i])
      }
      return visibles
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

.event-form-container
  &::v-deep
    h3.header-content
      padding: 0.25rem 0.5rem
      margin-top: 3rem
      margin-bottom: 1rem

    .range-slider-input-container
      +app-mobile
        margin-bottom: 3rem
.preview-notice
  color: white
  font-size: 0.75rem
  margin-bottom: 0.25rem
</style>
