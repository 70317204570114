<template>
  <div class="community-card">
    <a
      class="community-card-content"
      :href="showPath"
    >
      <figure class="is-mr-3">
        <img
          class="media__thumbnail"
          :src="imageUrl"
        >
      </figure>
      <div class="content">
        <span
          class="name"
          v-text="name"
        />
        <div class="is-mb-1">
          <CommunityOrganizationName
            v-if="organization"
            :name="organization.name"
          />
          <CommunityPrivacy :privacy="privacy" />
          <CommunityJoinPolicy :join-policy="joinPolicy" />
        </div>
        <div
          v-if="description.length > 0"
          class="is-mb-1 description"
          v-html="description"
        />
        <div
          v-if="address.length > 0"
          class="address"
          v-text="address"
        />
      </div>
    </a>
    <div
      v-if="joined"
      class="joined-mark"
    >
      <div class="joined-mark-badge" />
      <FaceIcon :src="currentUser.avatarPath" />
      <div class="joined-mark-text">
        参加中
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    imageUrl: {
      type: String,
      required: true
    },
    privacy: {
      type: String,
      required: true
    },
    joinPolicy: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: ''
    },
    address: {
      type: String,
      default: ''
    },
    showPath: {
      type: String,
      required: true
    },
    organization: {
      type: Object,
      default: null
    },
    joined: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapState('session', ['currentUser'])
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

$border-color: #d7d7d7

.community-card
  background-color: white
  display: flex
  justify-content: flex-start
  align-items: center
  border: 1px solid $border-color
  margin-bottom: 5px
  border-radius: $radius-size
  position: relative

  .community-card-content
    display: flex
    justify-content: flex-start
    padding: 10px
    width: 100%
    .name
      font-size: 18px
    .description
      font-size: 12px
    .address
      font-size: 12px
      color: #666

  .joined-mark
    position: absolute
    overflow: hidden
    left: 0
    bottom: 0
    width: 3.5rem
    height: 3.5rem
    border-radius: 0 0 0 $radius-size
    .joined-mark-badge
      border-top: 3.5rem solid transparent
      border-left: 3.5rem solid $primary
    .joined-mark-text
      color: white
      font-weight: bold
      position: absolute
      left: 2px
      bottom: 6px
      font-size: 10px
    &::v-deep
      .face-icon
        position: absolute
        left: 6px
        bottom: 20px
        img
          width: 20px
          height: 20px
</style>
