<template>
  <div
    ref="map"
    class="the-map"
    :style="inlineStyle"
  />
</template>

<script>
import 'leaflet/dist/leaflet.css'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
import 'leaflet.fullscreen/Control.FullScreen.css'
import 'leaflet-loading/src/Control.Loading.css'
import GoogleMapsLoader from 'google-maps'
import axios from 'axios'
import L from 'leaflet'
import 'leaflet.vectorgrid'
import 'leaflet.markercluster'
import 'leaflet.gridlayer.googlemutant'
import 'leaflet.fullscreen'
import 'leaflet-loading'
import { isProduction } from 'lib/env'
import { isAndroid, isChrome } from 'lib/browser'

const ICON_SIZE = {
  'cluster-community': [59, 59],
  'cluster-event': [60, 60],
  'cluster-online-event-text': [59, 59],
  'cluster-online-event-video': [59, 59],
  'cluster-supporter': [59, 59],
  'cluster-organization': [59, 57],
  'pin-community': [59, 57],
  'pin-event': [61, 60],
  'pin-online-event-text': [59, 53],
  'pin-online-event-video': [59, 42],
  'pin-supporter-azukaru': [55, 61],
  'pin-supporter': [56, 62],
  'pin-supporter-tunagu': [56, 62],
  'pin-organization': [59, 57]
}

const CLUSTERED_MARKER_COUNT_UNIT = {
  supporter: '人',
  community: '件',
  event: '件',
  'online-event-text': '件',
  'online-event-video': '件',
  organization: '件'
}

export default {
  props: {
    mapHeight: {
      type: Number,
      default: 450
    },
    lat: {
      type: Number,
      default: 35.65858
    },
    lng: {
      type: Number,
      default: 139.745433
    },
    zoom: {
      type: Number,
      default: 12
    },
    maxZoom: {
      type: Number,
      default: 13
    },
    minZoom: {
      type: Number,
      default: 5
    },
    radius: {
      type: Number,
      default: null
    },
    markSupporter: {
      type: Boolean,
      default: false
    },
    markCommunity: {
      type: Boolean,
      default: false
    },
    markEvent: {
      type: Boolean,
      default: false
    },
    markOrganization: {
      type: Boolean,
      default: false
    },
    markUser: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      map: null,
      centerCircle: null,
      supporterCluster: null,
      communityCluster: null,
      eventCluster: null,
      onlineEventTextCluster: null,
      onlineEventVideoCluster: null,
      organizationCluster: null
    }
  },
  computed: {
    inlineStyle () {
      return { height: `${this.mapHeight}px` }
    }
  },
  watch: {
    radius () {
      this.$_drawCenterCircle()
    },
    lat () {
      this.$_moveCenter()
    },
    lng () {
      this.$_moveCenter()
    }
  },
  mounted () {
    GoogleMapsLoader.load(() => {
      this.$_buildMap()
      this.$_buildCluster()
      this.$_drawCenterCircle()
      this.$_fetchMarks()
    })
  },
  methods: {
    $_buildMap () {
      this.map = this.$_newMap()
      this.map.setView([this.lat, this.lng], this.zoom)
      this.map.setMinZoom(this.minZoom)
      this.map.setMaxZoom(this.maxZoom)
      this.map.addLayer(L.gridLayer.googleMutant({ type: 'roadmap' }))
      this.map.addControl(L.Control.loading({ separate: true }))
      this.centerCircle = L.circle([this.lat, this.lng], {
        color: '#bd10e0',
        weight: 1,
        fillColor: 'rgb(189, 16, 224)',
        fillOpacity: 0.1
      })
    },
    $_buildCluster () {
      this.supporterCluster = this.$_newMarkerClusterGroup('supporter')
      this.communityCluster = this.$_newMarkerClusterGroup('community')
      this.eventCluster = this.$_newMarkerClusterGroup('event')
      this.onlineEventTextCluster = this.$_newMarkerClusterGroup('online-event-text')
      this.onlineEventVideoCluster = this.$_newMarkerClusterGroup('online-event-video')
      this.organizationCluster = this.$_newMarkerClusterGroup('organization')
    },
    $_drawCenterCircle () {
      if (!this.radius) return
      if (!this.centerCircle) return

      this.centerCircle.setRadius(this.radius).addTo(this.map)
      this.map.fitBounds(this.centerCircle.getBounds())
    },
    $_moveCenter () {
      const latlng = { lat: this.lat, lng: this.lng }
      this.map.panTo(latlng)
      if (this.centerCircle) {
        this.centerCircle.setLatLng(latlng)
      }
    },
    $_fetchMarks () {
      if (this.markUser) {
        this.$_markUser()
      }
      if (this.markSupporter) {
        this.$_loadGeoJson('/markers/supporters.json', this.supporterCluster)
      }
      // NOTE: コミュニティが多くて重いので, 本番環境では表示しない
      if (this.markCommunity && !isProduction()) {
        this.$_loadGeoJson('/markers/communities.json', this.communityCluster)
      }
      if (this.markEvent) {
        this.$_loadGeoJson('/markers/events.json', this.eventCluster)
        this.$_loadGeoJson('/markers/online_events.json', (feature) => {
          const { properties: { onlineEventType } } = feature
          return onlineEventType === 'text' ? this.onlineEventTextCluster : this.onlineEventVideoCluster
        })
      }
      if (this.markOrganization) {
        this.$_loadGeoJson('/markers/organizations.json', this.organizationCluster)
      }
    },
    async $_markUser () {
      const { data } = await axios.get('/markers/users.json')
      const userLayer = L.vectorGrid.slicer(data, {
        rendererFactory: L.canvas.tile,
        vectorTileLayerStyles: {
          sliced: {
            radius: 4,
            weight: 1,
            stroke: true,
            color: 'black',
            fillOpacity: 1,
            fillColor: '#ffff99',
            fill: true
          }
        }
      })
      userLayer.addTo(this.map)
    },
    async $_loadGeoJson (path, clusterOrFunction) {
      const { data: { features } } = await axios.get(path)
      features.forEach((feature) => {
        const { geometry: { coordinates }, properties: { icon } } = feature
        const [lng, lat] = coordinates
        const [width, height] = ICON_SIZE[icon]
        const marker = L.marker([lat, lng], {
          icon: L.icon({
            iconUrl: `/mapmarkers/${icon}.png`,
            iconSize: [width, height]
          })
        }).on('click', () => {
          const { properties: { type, id } } = feature
          this.$emit('click-marker', { type, id })
        })
        const cluster = typeof clusterOrFunction === 'function' ? clusterOrFunction(feature) : clusterOrFunction
        cluster.addLayer(marker)
      })
    },
    $_newMarkerClusterGroup (featureType) {
      const unit = CLUSTERED_MARKER_COUNT_UNIT[featureType]
      return L.markerClusterGroup({
        iconCreateFunction: cluster => L.divIcon({
          html: `<div class="marker-cluster-icon" style="${this.$_markerClusterGroupStyle(featureType)}"><span>${cluster.getChildCount()}${unit}</span></div>`
        })
      }).addTo(this.map)
    },
    $_markerClusterGroupStyle (featureType) {
      return `background-image:url(/mapmarkers/cluster-${featureType}.png)`
    },
    $_newMap () {
      const map = L.map(this.$refs.map, {
        attributionControl: false,
        fullscreenControl: true,
        fullscreenControlOptions: {
          position: 'topright',
          forceSeparateButton: true
        }
      })
      if (isAndroid() && isChrome()) {
        map.on('zoomstart', () => { window.scrollTo({ top: 0 }) })
      }
      return map
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

::v-deep .marker-cluster-icon
  display: flex
  justify-content: center
  align-items: center
  color: white
  font-weight: bold
  width: 59px
  height: 59px
  margin-top: -29.5px
  margin-left: -29.5px

+app-mobile
  ::v-deep
    .leaflet-control-container .leaflet-top.leaflet-right
      display: none
</style>
