<template>
  <div
    class="talk"
    :class="{ myself: isMyself }"
  >
    <div class="talk-wrapper">
      <FaceIcon
        v-if="!isMyself"
        class="talk-icon"
        :src="writer.avatarPath"
      />
      <div class="talk-inner">
        <div class="talk-box">
          <span v-html="content" />
        </div>
      </div>
      <FaceIcon
        v-if="isMyself"
        class="talk-icon"
        :src="writer.avatarPath"
      />
    </div>
    <div
      v-if="hasImage"
      class="image-box"
      :class="{ myself: isMyself }"
    >
      <ImageModal
        v-for="image in imagePaths"
        :key="image.original"
        :src="image.thumb"
        :original-src="image.original"
      />
    </div>
  </div>
</template>

<script>
import ImageModal from './ImageModal'
import FaceIcon from './FaceIcon'

export default {
  components: {
    ImageModal, FaceIcon
  },
  props: {
    content: {
      type: String,
      required: true
    },
    writer: {
      type: Object,
      required: true
    },
    imagePaths: {
      type: Array,
      default: () => []
    },
    isMyself: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    hasImage () {
      return this.imagePaths.length > 0
    }
  }
}
</script>

<style scoped lang="sass">
@use 'sass:math'
@import '~stylesheets/resources'

.talk
  .talk-wrapper
    display: flex
    margin-bottom: 1rem
  .talk-icon
    flex-shrink: 0
    margin-left: 0.5rem
  .talk-inner
    flex: 1
    text-align: right
    max-width: calc(100% - 4rem)
  .talk-box
    display: inline-block
    width: 100%
    min-width: 70%
    background: #efeeee
    border-radius: 0 12px 12px 12px
    padding: 8px 12px 8px
    margin-top: math.div($size-face-icon-regular, 2)
    font-size: 75%
    font-weight: normal
    text-align: left
    color: #000
    word-wrap: break-word
    +mobile
      font-size: 16px
  .image-box
    display: block
    text-align: left
    margin-left: 65px
    margin-bottom: 1rem
    &::v-deep > div
      display: inline-block
      margin-left: 0
      width: 73px
      margin-right: 0.5rem
    &::v-deep .image-modal
      border-radius: 7px
  &.myself
    .talk-box
      border-radius: 12px  0 12px 12px
      background: #fde1ca
    .image-box
      margin-left: 0
      margin-right: 65px
</style>
