<template>
  <div>
    <a
      href="#"
      class="button is-primary is-rounded"
      @click.prevent="onClick"
      v-text="label"
    />
    <Portal to="modal">
      <BModal
        :active.sync="isModalActive"
        :width="320"
      >
        <div
          class="modal-card"
          style="width: auto"
        >
          <header class="modal-card-head">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <p
              class="modal-card-title"
              v-html="modalTitle"
            />
          </header>
          <section class="modal-card-body">
            <ul>
              <li
                v-for="nav in navPaths"
                :key="nav.path"
                class="action-box__list-item"
              >
                <a :href="nav.path">
                  <div class="action-box__link">
                    <span v-text="nav.label" />
                  </div>
                </a>
              </li>
            </ul>
          </section>
        </div>
      </BModal>
    </Portal>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    modalTitle: {
      type: String,
      required: true
    },
    navPaths: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      isModalActive: false
    }
  },
  methods: {
    onClick () {
      this.isModalActive = true
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

.modal-card-title
  text-align: center
  font-size: 16px
.modal-card-body
  border-bottom-left-radius: 6px
  border-bottom-right-radius: 6px
  padding: 0
  &::v-deep
    ul
      li
        a
          font-size: 20px

.action-box__list-item
  border-top: 1px solid #ddd
  &:first-child
    border-top: 0
  &.is-helpee
    i
      color: #5cac00
  &.is-helper
    i
      color: #f2796e
  a
    display: flex
    font-weight: bold
    padding: 15px 40px
    align-items: center
    i
      font-size: 32px
      text-align: left
      &:nth-child(2)
        font-size: 22px
        margin-left: 6px
        width: 30px
    .action-box__link
      display: flex
      flex-direction: column
      align-items: flex-start
      margin-left: 8px
      line-height: 1.3
      p
        font-weight: normal
        font-size: 0.8em
</style>
