<template>
  <div class="share-friend-box-container">
    <template v-if="inModal && openAuthModal">
      <LevelUpAuth
        :user="user"
        @submit="onAuthSubmit"
      />
    </template>
    <template v-else>
      <div
        class="box share-friend-box"
        :class="{ 'in-modal': inModal }"
      >
        <ShareFriendAnimation
          v-if="isAnimating"
          @animation-finished="isAnimating = false"
        />
        <div class="left">
          <a :href="userShowPath">
            <FaceIcon
              class="is-mb-3"
              :src="user.avatarPath"
            >
              <div
                class="name"
                v-text="user.fullName"
              />
              <div
                class="address"
                v-text="user.filtered_address"
              />
              <div
                v-if="isMine"
                class="tag"
              >
                あなた
              </div>
            </FaceIcon>
          </a>
          <FriendGauge
            v-if="!isMine"
            :friend-level="approvedLevel"
            :is-small="true"
          />
        </div>
        <div class="right">
          <div class="title has-text-centered is-mb-2">
            シェア友レベル
          </div>
          <div
            v-if="selectedLevel === 0 && !isMine"
            class="is-mt-4 is-mb-3 has-text-centered has-text-weight-bold"
          >
            どのシェア（頼り合い）をしたいですか？
          </div>
          <div class="level-buttons">
            <div :class="levelButtonContainerClass(1)">
              <button
                type="button"
                data-level="1"
                @click="onClickLevel"
              >
                モノ
              </button>
            </div>
            <div :class="levelButtonContainerClass(2)">
              <button
                type="button"
                data-level="2"
                @click="onClickLevel"
              >
                予定
              </button>
            </div>
            <div :class="levelButtonContainerClass(3)">
              <button
                type="button"
                data-level="3"
                @click="onClickLevel"
              >
                託児
              </button>
            </div>
          </div>
          <template v-if="isLevelMax">
            <p class="has-text-centered has-text-weight-bold is-my-4">
              すべてのシェアができる仲になりました
            </p>
          </template>
          <template v-else-if="selectedLevel > 0">
            <p
              class="comment"
              v-text="comment"
            />
            <div class="question-box">
              <div
                v-if="isSelectedLevelApproved"
                class="approved-comment"
                v-text="approvedComment"
              />
              <div
                v-else-if="isComming"
                class="comming-comment"
              >
                <i class="fal fa-envelope" />
                <span v-text="commingComment" />
              </div>
              <BCheckbox
                v-else
                v-model="questionChecked"
                class="question-checkbox"
                :class="{ 'has-error': questionCheckError }"
                :disabled="questionDisabled"
              >
                <span
                  class="question"
                  v-text="question"
                />
              </BCheckbox>
              <div
                v-if="questionCheckError"
                class="question-checkbox-error"
              >
                チェックボックスを確認してください
              </div>
            </div>
            <div
              v-if="isSelectedLevelApproved"
              class="congrats-message is-mb-3"
            >
              <i class="fas fa-award" />
              <span v-text="congratsMessage" />
            </div>
            <button
              v-else
              type="button"
              :class="levelUpButtonClass"
              class=" is-mb-2"
              :disabled="levelUpButtonDisabled"
              @click="onLevelUp"
              v-text="buttonText"
            />
          </template>
          <a
            v-if="showChatPath"
            :href="chatPath"
            class="button is-primary is-rounded is-outlined is-fullwidth"
          >
            <i class="fal fa-comment is-mr-1" />
            メッセージ
          </a>
        </div>
        <BModal
          :active.sync="openAuthModal"
          has-modal-card
        >
          <LevelUpAuth
            :user="user"
            @submit="onAuthSubmit"
          />
        </BModal>
      </div>
    </template>
  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import FaceIcon from 'components/FaceIcon.vue'
import FriendGauge from 'components/FriendGauge.vue'
import LevelUpAuth from 'components/LevelUpAuth.vue'
import ShareFriendAnimation from 'components/ShareFriendAnimation.vue'
import { showError } from 'lib/app-toast'

const COMMENTS = {
  1: 'モノの貸し借りや譲り合いができる仲になります',
  2: 'ごはんやお出かけ等の予定に誘いあえる仲になります',
  3: '送迎や託児が頼りあえる仲になります'
}

const QUESTIONS = {
  1: name => `${name}さんとは知り合いですか？`,
  2: name => `${name}さんは、よく知る仲ですか？`,
  3: name => `${name}さんとは、親子共によく知る仲ですか？`
}

const ABILITIES = {
  1: 'モノのシェア友',
  2: '予定のシェア友',
  3: '託児のシェア友'
}

export default {
  components: {
    FaceIcon, FriendGauge, LevelUpAuth, ShareFriendAnimation
  },
  props: {
    levelByMe: {
      type: Number,
      required: true
    },
    levelByFriend: {
      type: Number,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    defaultSelectedLevel: {
      type: Number,
      default: null
    },
    inModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    const selectedLevel = this.getDefaultSelectedLevel()
    return {
      selectedLevel,
      friendLevelByMe: this.levelByMe,
      friendLevelByFriend: this.levelByFriend,
      openAuthModal: false,
      questionChecked: this.isQuestionChecked(selectedLevel, this.levelByMe, this.levelByFriend),
      questionCheckError: false,
      isAnimating: false
    }
  },
  computed: {
    ...mapState('session', ['currentUser']),
    isMine () {
      return this.currentUser && this.user.id === this.currentUser.id
    },
    approvedLevel () {
      return Math.min(this.friendLevelByMe, this.friendLevelByFriend)
    },
    levelButtonContainerClass () {
      return level => ({
        'level-button-container': true,
        selected: this.selectedLevel === level,
        disabled: this.isLevelButtonDisabled(level),
        requested: this.isRequested(level),
        approved: this.isApproved(level)
      })
    },
    comment () {
      return COMMENTS[this.selectedLevel]
    },
    approvedComment () {
      return `${ABILITIES[this.selectedLevel]}として承認されました`
    },
    question () {
      return QUESTIONS[this.selectedLevel](this.user.lastName)
    },
    congratsMessage () {
      return `「${ABILITIES[this.selectedLevel]}」をクリア`
    },
    buttonText () {
      if (this.isComming) {
        return '承認'
      }
      if (this.selectedLevel <= this.friendLevelByMe) {
        return `${this.user.lastName}さんの承認待ち`
      }
      return `「${ABILITIES[this.selectedLevel]}」を申請する`
    },
    levelUpButtonClass () {
      return {
        'level-up-button': true,
        button: true,
        'is-rounded': true,
        'is-fullwidth': true,
        requested: this.isRequested(this.selectedLevel),
        requestable: !this.isRequested(this.selectedLevel) && !this.questionCheckboxHasError,
        unchecked: !this.isRequested(this.selectedLevel) && this.questionCheckboxHasError
      }
    },
    levelUpButtonDisabled () {
      return this.isMine ||
        this.isApproved(this.selectedLevel) ||
        this.isRequested(this.selectedLevel)
    },
    questionDisabled () {
      return this.isMine ||
        this.isApproved(this.selectedLevel) ||
        this.isRequested(this.selectedLevel)
    },
    userShowPath () {
      return `/users/${this.user.id}`
    },
    commingComment () {
      return `${this.user.lastName}さんより「${ABILITIES[this.selectedLevel]}」申請が届いています`
    },
    isSelectedLevelApproved () {
      return this.isApproved(this.selectedLevel)
    },
    questionCheckboxHasError () {
      return !this.questionChecked && !this.questionDisabled && !this.isComming
    },
    isComming () {
      return this.friendLevelByMe < this.selectedLevel &&
        this.selectedLevel <= this.friendLevelByFriend
    },
    isLevelMax () {
      return this.approvedLevel === 3
    },
    showChatPath () {
      if (!this.currentUser) return false
      if (!this.currentUser.supporter) return false

      return true
    },
    chatPath () {
      return `/users/${this.user.id}/one_to_one_chat`
    }
  },
  methods: {
    onClickLevel (ev) {
      let { level } = ev.target.dataset
      level = parseInt(level, 10)
      if (!this.isLevelButtonDisabled(level) && level !== this.selectedLevel) {
        this.selectedLevel = level
        this.onLevelChanged()
      }
    },
    onLevelUp () {
      if (this.isApproved(this.selectedLevel) || this.isRequested(this.selectedLevel)) {
        return
      }
      if (this.questionCheckboxHasError) {
        this.questionCheckError = true
        return
      }

      if (this.selectedLevel === 3 && this.friendLevelByFriend < 3) {
        this.openAuthModal = true
      } else {
        this.requestLevelUp({ level: this.selectedLevel })
      }
    },
    isApproved (level) {
      return level <= this.approvedLevel
    },
    isRequested (level) {
      return !this.isApproved(level) && level <= this.friendLevelByMe
    },
    onAuthSubmit (phoneNumber) {
      this.requestLevelUp({ level: 3, phoneNumber })
    },
    async requestLevelUp ({ level, phoneNumber }) {
      const params = { friendship: { level, auth_key: phoneNumber } }
      try {
        const { data: { levelByFriend } } = await axios.put(`/users/${this.user.id}/friendship.json`, params)
        this.friendLevelByMe = level
        this.friendLevelByFriend = levelByFriend
        this.onLevelChanged()
        if (this.isLevelMax) {
          // TODO: 秋葉さんがアニメーションのトキメキ感を調整する
          // this.isAnimating = true
        }
      } catch (e) {
        const { data: { errors } } = e.response
        if (this.isUnacceptableByLackedProfile(errors)) {
          this.redirectToLackedProfile()
        } else if (errors) {
          errors.forEach(_ => showError(_))
        } else {
          showError(e.message)
        }
      }
    },
    isUnacceptableByLackedProfile (errors) {
      return errors && errors.length === 1 && errors[0] === 'シェア友申請の要件を満たしていません。'
    },
    redirectToLackedProfile () {
      const backUrl = window.location.toString()
      window.location.href = `/lacked_profiles/search_user?back_url=${encodeURIComponent(backUrl)}`
    },
    isQuestionChecked (level, levelByMe, levelByFriend) {
      const approvedLevel = Math.min(levelByMe, levelByFriend)
      return level <= approvedLevel || level <= levelByMe
    },
    close () {
      this.openAuthModal = false
    },
    onLevelChanged () {
      // 任意のタイミングで値を変えたいのであえて computed ではなく data としている
      this.questionChecked = this.isQuestionChecked(
        this.selectedLevel, this.friendLevelByMe, this.friendLevelByFriend
      )
      this.questionCheckError = false
    },
    getDefaultSelectedLevel () {
      const approved = Math.min(this.levelByMe, this.levelByFriend)
      let level
      if (this.levelByFriend > approved) {
        level = this.levelByFriend
      } else if (this.levelByMe > approved) {
        level = this.levelByMe
      } else if (approved === 0) {
        level = 0
      } else {
        level = Math.min(3, approved + 1)
      }
      return this.defaultSelectedLevel || level
    },
    isLevelButtonDisabled (level) {
      return this.isMine || level <= this.approvedLevel
    }
  }
}
</script>

<style lang="sass" scoped>
@import '~stylesheets/resources'
.share-friend-box-container
  &:not(last-child)
    margin-bottom: 1.5rem

.box
  display: flex
  align-items: center
  padding-top: 10px
  padding-bottom: 15px
  padding-left: 5px
  &.in-modal
    margin: 20px
  +app-mobile
    padding-left: 0
  & > *
    box-sizing: border-box
  +app-mobile
    align-items: flex-start
    padding-left: 0.5rem
    padding-right: 0.5rem

.share-friend-box
  position: relative
  .left
    width: 110px
    text-align: center
    +app-mobile
      width: 90px
    .face-icon
      &::v-deep
        > .icon
          display: inline-flex
  .right
    width: calc(100% - 110px)
    padding-left: 1rem
    +app-mobile
      width: calc(100% - 90px)
      // flex-grow: 1
    font-size: 14px

  .title
    font-size: 10px
    font-weight: normal

  .name
    margin: 0 0 3px
    font-weight: 600
    text-align: center
    font-size: 12px
    span
      font-size: 9px
      font-weight: normal
      display: inline-block
  .address
    font-size: 10px
    line-height: 1
    padding: 0 15px
    text-align: left
    line-height: 1.3
    +app-mobile
      padding: 0 5px
  .comment
    margin-bottom: 10px
    font-weight: bold
    line-height: 1.3
  .comming-comment
    margin-bottom: 10px
    font-size: 12px
    font-weight: bold
    line-height: 1.3
    color: $primary
  .question-box
    margin-bottom: 12px
    .question
      display: inline-block
      line-height: 1.3
    .question-checkbox
      display: flex
      align-items: flex-start
    .b-checkbox.checkbox.question-checkbox
      &[disabled]
        opacity: 1
        color: inherit
      &.has-error
        color: $danger
        margin-bottom: 0
        .question
          text-decoration: underline
    .question-checkbox-error
      font-size: 12px
      margin-left: 27px
      color: $danger
  .approved-comment
    line-height: 1.3
    margin-bottom: 10px
  .congrats-message
    text-align: center
    > i
      color: $primary

.level-buttons
  position: relative
  display: flex
  justify-content: space-between
  margin-bottom: 10px
  &::before
    position: absolute
    top: calc(50% - 4px)
    content: ''
    display: block
    width: 100%
    height: 8px
    background: #eee

  .level-button-container
    position: relative
    text-align: center
    z-index: 1
    button
      width: 40px
      height: 40px
      padding: 0
      border: $primary 2px solid
      border-radius: 50%
      color: $primary
      background-color: white
      cursor: pointer
      font-size: 13px
      font-weight: bold
      text-align: center
      white-space: nowrap
      line-height: 26px

    &.selected
      button
        border-color: $primary
        color: white
        background-color: $primary
    &.disabled
      button
        border-color: #9b9b9b
        color: white
        background-color: #9b9b9b
        cursor: not-allowed
    &.requested, &.approved
      &::after
        display: flex
        font-size: 9px
        border: 1px solid $primary
        border-radius: 50%
        width: 16px
        height: 16px
        justify-content: center
        align-items: center
        position: absolute
        right: -5px
        bottom: -3px
        font-weight: bold
    &.requested
      button
        border-color: #9b9b9b
        color: white
        background-color: #9b9b9b
      &::after
        content: '待'
        color: $primary
        background-color: white
    &.approved
      &::after
        content: '済'
        color: white
        background-color: $primary

.level-up-button
  font-size: 16px
  font-weight: bold
  +app-mobile
    font-size: 10px

  &:disabled
    opacity: 1
  &.unchecked
    border-color: $primary
    background-color: white
    color: $primary
  &.requested
    border-color: #9b9b9b
    background-color: #9b9b9b
    color: white
  &.requestable
    border-color: $primary
    background-color: $primary
    color: white

.share-friend-animation
  position: absolute
  top: 0
  left: 0

::v-deep
  .b-checkbox.checkbox input[type=checkbox] + .check
    margin-top: 2px
    border: 2px solid #ec6b00
    opacity: .5
  .b-checkbox.checkbox input[type=checkbox]:checked + .check
    opacity: 1
</style>
