<template>
  <div
    :class="{ myself: isMyself }"
    class="talk"
  >
    <a
      v-if="!isMyself"
      :href="writerShowPath"
    >
      <FaceIcon :src="writer.avatarPath">
        <span v-text="writer.fullName" />
      </FaceIcon>
    </a>
    <div class="talk-inner">
      <div
        v-if="hasImage"
        class="image-box"
      >
        <ImageModal
          :src="imageThumbUrl"
          :original-src="imageUrl"
        />
        <DateTimeOrTime
          :value="updatedAt"
          :auto-update="true"
          class="chat-time"
        />
      </div>
      <div
        v-if="!hasImage"
        class="talk-box"
      >
        <div v-html="content" />
        <DateTimeOrTime
          :value="updatedAt"
          :auto-update="true"
          class="chat-time"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { writerShowPath } from 'lib/paths'
import ImageModal from './ImageModal'
import DateTimeOrTime from './DateTimeOrTime'
import FaceIcon from './FaceIcon'

export default {
  components: {
    ImageModal, DateTimeOrTime, FaceIcon
  },
  props: {
    content: {
      type: String,
      required: true
    },
    updatedAt: {
      type: String,
      required: true
    },
    imageUrl: {
      type: String,
      default: null
    },
    imageThumbUrl: {
      type: String,
      default: null
    },
    writer: {
      type: Object,
      required: true
    },
    isMyself: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    hasImage () {
      return this.imageUrl != null
    },
    writerShowPath () {
      return writerShowPath(this.writer)
    }
  }
}
</script>

<style scoped lang="sass">
@use 'sass:math'
@import '~stylesheets/resources'

$chat-time-text-size: 9px
$chat-time-text-spacing: $chat-time-text-size + 3px

.talk
  padding: 15px 10px $chat-time-text-spacing
  display: flex
  justify-content: flex-start
  .face-icon
    margin-right: .5rem
    flex-shrink: 0
    &::v-deep
      .name
        font-size: 75%
  .talk-inner
    flex: 1
    max-width: calc(100% - 4rem)
  .talk-box
    display: inline-block
    position: relative
    background: #eee
    border-radius: 0 12px 12px 12px
    padding: 8px 12px 8px
    margin-top: math.div($size-face-icon-regular, 2)
    font-size: 75%
    max-width: 85%
    font-weight: normal
    color: #000
    word-wrap: break-word
  .image-box
    display: inline-block
    position: relative
    max-width: 70%
    margin-top: math.div($size-face-icon-regular, 2)
    &::v-deep .image-modal
      max-height: 400px
      max-width: 100%
      border-radius: 8px
      display: block
  .chat-time
    display: block
    position: absolute
    bottom: -$chat-time-text-spacing
    right: 0
    font-size: $chat-time-text-size
    line-height: 1
    color: #666
    text-align: right
  &.myself
    justify-content: flex-end
    .talk-inner
      text-align: right
      max-width: 100%
    .talk-box
      max-width: 75%
      text-align: left
      background: #fde1ca
      border-radius: 12px 0 12px 12px
      margin-top: 0
    .image-box
      margin-top: 0
    .chat-time
      right: unset
      left: 0
      text-align: left
+mobile
  .talk .talk-box
    font-size: 16px
</style>
