import { render, staticRenderFns } from "./TheChats.vue?vue&type=template&id=3ab4c73a&scoped=true&"
import script from "./TheChats.vue?vue&type=script&lang=js&"
export * from "./TheChats.vue?vue&type=script&lang=js&"
import style0 from "./TheChats.vue?vue&type=style&index=0&id=3ab4c73a&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ab4c73a",
  null
  
)

export default component.exports