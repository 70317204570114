<template>
  <BField
    :label="label"
    :message="errorMessage"
    :type="status"
    :label-for="id"
    :custom-class="customClass"
  >
    <BSelect
      :id="id"
      v-model="selectedValues"
      :name="name"
      :class="{'is-mbfs-small': isMobileSmall}"
      @input="handleInput"
    >
      <option
        v-for="opt in options"
        :key="opt.value"
        :value="opt.value"
        v-text="opt.label"
      />
    </BSelect>
  </BField>
</template>

<script>
import * as inputs from './inputs'

export default {
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: null
    },
    value: {
      type: [String, Number],
      default: () => []
    },
    errors: {
      type: Array,
      default: () => []
    },
    collection: {
      type: Array,
      required: true
    },
    includeBlank: {
      type: Boolean,
      default: false
    },
    onChange: {
      type: Function,
      default: () => {}
    },
    isMobileSmall: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    isPublished: {
      type: Boolean,
      default: false
    },
    addInputtedClass: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return { selectedValues: this.value, displayErrors: this.errors, isInputted: false }
  },
  computed: {
    status () {
      return inputs.status(this.errors, this.isInputted)
    },
    id () {
      return inputs.idForName(this.name)
    },
    customClass () {
      return inputs.customClass(this.$props)
    },
    options () {
      const opts = this.collection.map((e) => {
        const [label, value] = Array.isArray(e) ? e : [e, e]
        return { label, value }
      })
      if (this.includeBlank) {
        return [{ label: '', value: '' }].concat(opts)
      }
      return opts
    },
    errorMessage () {
      if (this.isInputted) {
        return this.displayErrors.filter(message => !message.match(/を入力してください/i))
      }
      return this.displayErrors
    }
  },
  methods: {
    handleInput (value) {
      this.onChange(value)
      this.isInputted = this.addInputtedClass && value.length > 0
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'
::v-deep
  @import '~stylesheets/components/required-label'
  @import '~stylesheets/components/published-label'
::v-deep .select:not(.is-multiple):not(.is-loading)::after
  border-width: 2px
  height: 0.425em
  width: 0.425em
  margin-top: -0.2875em
::v-deep
  +mobile
    .is-mbfs-small
      font-size: 14px
    .select select,
    .select select:not([multiple])
      padding-right: 1.5em
    .select:not(.is-multiple):not(.is-loading)::after
      right: 0.75em
</style>
