<template>
  <div class="photo-uploader">
    <BField
      :label="label"
      :message="errors"
      :type="status"
      :custom-class="customClass"
      :class="classObject"
    >
      <div
        ref="viewer"
        class="photo-uploader-box"
        :class="{
          'is-error': status,
          'is-selected': bgImageURL!=='none'
        }"
        :style="{
          'width': width,
          'height': height
        }"
      >
        <div
          class="resolve-photo-container"
          :style="{'background-image': bgImageURL}"
        />
        <div
          v-if="bgImageURL==='none'"
          class="preview-photo-container"
        >
          <i class="photo-icon fal fa-image" />
          <div
            class="label"
            v-text="message"
          />
        </div>
        <input
          class="inputfile"
          type="file"
          accept="image/*"
          :name="name"
          @change="fileChanged"
        >
      </div>
    </BField>
    <slot />
  </div>
</template>

<script>
import EXIF from 'exif-js'
import * as inputs from './inputs'

export default {
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: null
    },
    displayWidth: {
      type: String,
      default: '30vw'
    },
    displayHeight: {
      type: String,
      default: '30vw'
    },
    errors: {
      type: Array,
      default: () => []
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    isInColumns: {
      type: Boolean,
      default: false
    },
    isNoLabel: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: null
    }
  },
  data () {
    const url = this.value ? `url(${this.value})` : 'none'
    return {
      bgImageURL: url,
      message: '写真を送信'
    }
  },
  computed: {
    status () {
      return inputs.status(this.errors)
    },
    customClass () {
      return inputs.customClass(this.$props)
    },
    classObject () {
      return {
        'is-no-label': this.isNoLabel
      }
    },
    width () {
      return this.isInColumns ? '100%' : this.displayWidth
    },
    height () {
      return this.isInColumns ? '120px' : this.displayHeight
    }
  },
  methods: {
    fileChanged (e) {
      const file = e.target.files[0]
      EXIF.getData(file, () => {
        if ('exifdata' in file) {
          const exif = file.exifdata
          let rotate = 0
          if (exif && exif.Orientation) {
            switch (exif.Orientation) {
              case 3:
                rotate = 180
                break
              case 6:
                rotate = 90
                break
              case 8:
                rotate = -90
                break
              default:
                rotate = 0
                break
            }
          }
          const viewer = this.$refs.viewer
          viewer.style.transform = `rotate(${rotate}deg)`
          viewer.style.webkitTransform = `rotate(${rotate}deg)`
        }
      })
      const oURL = URL.createObjectURL(file)
      this.bgImageURL = `url(${oURL})`
      this.$emit('change', this.name, oURL)
    }
  }
}
</script>

<style lang="sass" scoped>
::v-deep
  @import "~stylesheets/components/required-label"

$error-color: #ff3860

.photo-uploader-box
  display: block
  position: relative
  background-color: rgb(238, 238, 238)
  border: 2px dotted rgb(215, 215, 215)
  border-radius: 6px
  cursor: pointer
  transition: 0.2s
  overflow: hidden
  &:hover
    background-color: darken(rgb(238, 238, 238), 10%)
    border: 2px dotted darken(rgb(215, 215, 215), 20%)
    .photo-icon,
    .label
      color: white
  &.is-selected
    border: none
  &.is-error
    border-color: $error-color
    .photo-icon,
    .label
      color: $error-color

.resolve-photo-container
  background-repeat: no-repeat
  background-position: center
  background-size: cover
  width: 100%
  height: 100%
  position: absolute
  top: 0
  left: 0
  transition: 0.3s
  &:hover
    border: none
    background-color: transparent
    transition: 0.2s
    filter: brightness(0.8)

.preview-photo-container
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  position: relative
  height: 100%

.photo-icon
  font-size: 30px
  color: #ccc
  transition: 0.2s

.label
  color: #999
  transition: 0.2s

.inputfile
  appearance: none
  width: 100%
  height: 100%
  position: absolute
  top: 0
  left: 0
  opacity: 0
  cursor: pointer

.field.is-no-label > ::v-deep .label
  display: none
</style>
