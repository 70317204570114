<template>
  <div>
    <slot />
  </div>
</template>

<script>
export default {
}
</script>

<style scoped lang="sass">
.community-main
  position: relative
  .community-cover
    display: flex
    justify-content: center
    align-items: center
    height: 185px
    background-size: cover
    background-position: center
    background-image: linear-gradient(#f76b1c, #fa61ba)
    .add-cover-button
      display: inline-block
      font-size: 10px
      color: rgba(#fff, 0.8)
      border: 2px solid rgba(#fff, 0.5)
      text-shadow: 0 0 10px #333
      padding: 0.86em 1.86em
      border-radius: 2.4em
      font-weight: 600
      &:active
        background-color: rgba(#333, 0.1)
      i
        margin-right: 5px

.community-name
  margin-left: 20px

.community-entries-title
  font-size: 1.2rem
  line-height: 1.5
  font-weight: bold

.community-entries-photos
  display: flex
  justify-content: flex-start
  flex-wrap: wrap
  margin-bottom: 0.5rem

.community-entries-photo
  width: 31.333333%
  margin-right: 3%
  margin-bottom: 0.25rem
  &:nth-child(3n)
    margin-right: 0
  &::v-deep img
    border-radius: 6px
</style>
