<template>
  <div :class="cardClass">
    <div class="sharing-card-head">
      <a
        :href="userShowPath"
        @click="onClickUser"
      >
        <FaceIconRelations
          class="is-large is-mobile-same"
          :face-icon="faceIcon"
        >
          <template>
            <span
              class="sharing-card-name"
              v-text="user.fullName"
            />
            <span class="sharing-card-honorific">
              さん
            </span>
          </template>
        </FaceIconRelations>
      </a>
      <span v-if="oneToOneChattable">
        <a
          :href="oneToOneChatPath"
          class="chat-button button is-primary is-rounded is-outlined"
        >
          <i class="fal fa-comment is-mr-1" />
          メッセージ
        </a>
      </span>
    </div>
    <a
      class="sharing-card-box"
      :href="path"
    >
      <div class="sharing-card-cat">
        <i
          v-for="(icon, i) in icons"
          :key="i"
          :class="icon"
        />
        <span v-text="title" />
      </div>
      <div class="sharing-card-date">
        <span
          :title="createdAt"
          v-text="formattedDate"
        />
        <span
          v-if="!published"
          class="tag is-danger is-ml-2"
        >
          発信前
        </span>
      </div>
      <h3 :class="sharingCardTitleClass">
        <strong v-text="subject" />
      </h3>
      <p
        v-if="!!body"
        class="sharing-card-txt"
        v-html="body"
      />
      <div
        v-if="hasImage"
        class="sharing-card-img"
      >
        <ImageModal
          v-for="image in imagePaths"
          :key="image.original"
          :src="image.thumb"
          :original-src="image.original"
        />
      </div>
      <hr v-if="formattedRange || formattedDeadline || (!hidePrice && price)">
      <div
        v-if="formattedRange || formattedDeadline"
        class="sharing-card-iconinfo"
      >
        <i class="fal fa-calendar-alt" />
        <p v-if="formattedRange">
          <span v-text="formattedRange" />
          <small>
            <span v-text="formattedDeadline" />
            <span
              v-if="earlyClosing"
              class="is-ml-2"
            >
              早期終了あり
            </span>
          </small>
        </p>
        <p v-else>
          <span>
            <span v-text="formattedDeadline" />
            <span
              v-if="earlyClosing"
              class="is-ml-2"
            >
              早期終了あり
            </span>
          </span>
        </p>
      </div>
      <div v-if="!hidePrice">
        <div
          v-if="price"
          class="sharing-card-iconinfo"
        >
          <i class="fal fa-coins" />
          <p v-text="formattedPrice" />
        </div>
      </div>
      <div
        v-if="sharingState()"
        class="sharing-card-replied-outer"
      >
        <div class="sharing-card-replied-badge" />
        <span :class="['sharing-card-replied-text', `sharing-card-replied-text-${sharingState()}`]" v-text="sharingStateMessage" />
      </div>
    </a>
    <Portal to="modal">
      <BModal :active.sync="isCorporateUserModalActive">
        <CorporateUserModalContent v-bind="user" />
      </BModal>
    </Portal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import FaceIconRelations from 'components/FaceIconRelations.vue'
import ImageModal from 'components/ImageModal.vue'
import CorporateUserModalContent from 'components/CorporateUserModalContent'
import { format as dateFormat, isSameDay, isSameYear } from 'date-fns'

const TITLES = {
  entry: '預かって',
  lending: '貸すよ',
  borrowing: '貸して',
  giving: '譲るよ',
  taking: '譲って',
  activeInviting: 'お誘い',
  passiveInviting: '誘って',
  support: '預かるよ',
  news: 'お知らせ'
}

const ICONS = {
  entry: ['fa fa-child'],
  lending: ['fa fa-book-spells', 'fa fa-exchange'],
  borrowing: ['fa fa-book-spells', 'fa fa-exchange'],
  giving: ['fa fa-gift', 'fa fa-long-arrow-right'],
  taking: ['fa fa-gift', 'fa fa-long-arrow-right'],
  activeInviting: ['fa fa-utensils'],
  passiveInviting: ['fa fa-utensils'],
  support: ['fa fa-child'],
  news: ['fa fa-bullhorn']
}

const COLORS = {
  entry: 'green',
  lending: 'pink',
  borrowing: 'green',
  giving: 'pink',
  taking: 'green',
  activeInviting: 'pink',
  passiveInviting: 'green',
  support: 'pink',
  news: 'pink'
}

const SHARING_STATE_MESSAGES = {
  solved: 'シェア成立',
  closed: '募集終了',
  replied: '返信済'
}

export default {
  components: { FaceIconRelations, ImageModal, CorporateUserModalContent },
  props: {
    user: {
      type: Object,
      required: true
    },
    children: {
      type: Array,
      default: () => []
    },
    sharingType: {
      type: String,
      required: true
    },
    subject: {
      type: String,
      default: null
    },
    body: {
      type: String,
      default: null
    },
    startedAt: {
      type: String,
      default: null
    },
    finishedAt: {
      type: String,
      default: null
    },
    deadlineOn: {
      type: String,
      default: null
    },
    createdAt: {
      type: String,
      required: true
    },
    requestState: {
      type: String,
      default: null
    },
    decided: {
      type: Boolean,
      default: false
    },
    closed: {
      type: Boolean,
      default: false
    },
    exceededDeadline: {
      type: Boolean,
      default: false
    },
    price: {
      type: [Number, String],
      default: null
    },
    imagePaths: {
      type: Array,
      default: () => []
    },
    path: {
      type: String,
      default: '#'
    },
    hideIcon: {
      type: Boolean,
      default: false
    },
    hidePrice: {
      type: Boolean,
      default: false
    },
    displayInOrganizationPage: {
      type: Boolean,
      default: false
    },
    earlyClosing: {
      type: Boolean,
      default: false
    },
    didReply: {
      type: Boolean,
      default: false
    },
    oneToOneChattable: {
      type: Boolean,
      default: false
    },
    published: {
      type: Boolean,
      default: true
    },
    truncateSubject: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      isCorporateUserModalActive: false
    }
  },
  computed: {
    ...mapState('session', ['currentUser']),
    isMine () {
      return this.currentUser && this.user.id === this.currentUser.id
    },
    cardClass () {
      return {
        'sharing-card': true,
        'is-mb-3': true,
        [`type-${COLORS[this.sharingType]}`]: true,
        'is-mine': this.isMine,
        'is-not-mine': !this.isMine,
        'hide-icon': this.hideIcon,
        'display-in-organization-page': this.displayInOrganizationPage
      }
    },
    faceIcon () {
      return { faces: [{ src: this.user.avatarPath }] }
    },
    title () {
      return TITLES[this.sharingType]
    },
    formattedDate () {
      return this.formatDate(this.createdAt)
    },
    formattedRange () {
      if (this.startedAt && this.finishedAt) {
        if (isSameDay(this.startedAt, this.finishedAt)) {
          return `${this.formatDateTime(this.startedAt)} 〜 ${dateFormat(this.finishedAt, 'H:mm')}`
        }
        return `${this.formatDateTime(this.startedAt)} 〜 ${this.formatDateTime(this.finishedAt)}`
      }
      if (this.startedAt) {
        return this.formatDateTime(this.startedAt)
      }
      return ''
    },
    formattedDeadline () {
      if (this.deadlineOn) {
        return `締め切り ${this.formatDate(this.deadlineOn)}まで`
      }
      return ''
    },
    formattedPrice () {
      if (this.price === 'point') {
        return 'お気持ち'
      }
      return `${this.price}円`
    },
    sharingStateMessage () {
      return SHARING_STATE_MESSAGES[this.sharingState()]
    },
    hasImage () {
      return this.imagePaths.length > 0
    },
    icons () {
      return ICONS[this.sharingType]
    },
    oneToOneChatPath () {
      return `/users/${this.user.id}/one_to_one_chat`
    },
    userShowPath () {
      return this.isCorporateUser ? '#' : `/users/${this.user.id}`
    },
    isCorporateUser () {
      return this.user.type === 'CorporateUser'
    },
    sharingCardTitleClass () {
      return {
        'sharing-card-title': true,
        unpublished: !this.published,
        truncated: this.truncateSubject
      }
    }
  },
  methods: {
    formatDate (date) {
      if (isSameYear(date, new Date())) {
        return dateFormat(date, 'M月D日')
      }
      return dateFormat(date, 'YYYY年M月D日')
    },
    formatDateTime (date) {
      if (isSameYear(date, new Date())) {
        return dateFormat(date, 'M月D日 H:mm')
      }
      return dateFormat(date, 'YYYY年M月D日 H:mm')
    },
    onClickUser (ev) {
      if (this.isCorporateUser) {
        ev.preventDefault()
        this.isCorporateUserModalActive = true
      }
    },
    sharingState () {
      if (this.sharingType == 'entry' && this.requestState == 'solved' ||
           this.sharingType != 'support' && this.decided) {
          return 'solved'
      } else if (this.closed || this.exceededDeadline) {
          return 'closed'
      } else if (this.didReply) {
        return 'replied'
      } else {
        return ''
      }
    }
  }
}
</script>

<style lang="sass" scoped>
@import '~stylesheets/resources'

// 支援：ピンク
$pink: (bg: #fff0ef, title: #f2796e, hr: rgba(182,127,186, 0.2))

// 頼る：グリーン
$green: (bg: #f0ffde, title: #5cac00, hr: rgba(134,168,50, 0.3))

=triangleColor($color)
  &::before
    border-left-color: $color
    border-right-color: $color

=triangleR
  display: inline-block
  width: 0
  height: 0
  position: absolute
  top: 20px
  right: -8px
  border-style: solid
  border-color: transparent transparent transparent transparent
  border-width: 6px 0 6px 8px
  content: ''

=triangleL
  display: inline-block
  width: 0
  height: 0
  position: absolute
  top: 20px
  left: -8px
  border-style: solid
  border-color: transparent transparent transparent transparent
  border-width: 6px 8px 6px 0
  content: ''

::v-deep .face-icon
  width: 100%
  > img.icon
    width: 65px
    height: 65px
    border: none
  +app-mobile
    font-size: inherit
    > img.icon
      width: 60px
      height: 60px
      border: none

.sharing-card
  display: flex
  width: 100%
  margin: 0 0 20px

  .sharing-card-head
    line-height: 1.2
    width: 86px
    text-align: center
  .sharing-card-name
    margin: 10px 0 0
    font-size: 13px
    font-weight: bold
  .sharing-card-honorific
    font-size: 10px
    font-weight: 700
    display: block
    line-height: 1
  .sharing-card-child
    & > div
      font-weight: 600
  .sharing-card-box
    position: relative
    width: calc(100% - 100px)
    padding: 24px 20px 12px
    margin-top: 13px
    border-radius: 10px
    hr
      margin: 8px 0
      height: 1px
    > *:last-child
      margin-bottom: 0
  .sharing-card-cat
    position: absolute
    top: -13px
    left: 0
    height: 28px
    border-radius: 14px
    border: 2px solid #fff
    color: #fff
    line-height: 1
    padding: 5px 13px
    font-size: 14px
    font-weight: bold
    i + i
      display: inline-block
      margin-left: 2px
  .sharing-card-head
    order: 1
    .chat-button
      margin-top: 6px
      font-size: 10px
      line-height: 1

  .sharing-card-txt
    word-break: break-all
    line-height: 1.3
    font-size: 14px

  .sharing-card-img
    margin: 5px 0
    line-height: 0

    > div
      display: inline-block
      width: 44px
      max-width: 28%
      margin: 10px 10px 0 0
      &:nth-child(3n)
        margin-right: 0
      &::v-deep img
        border-radius: 4px

  .sharing-card-title
    margin: 0 0 6px
    line-height: 1.25
    font-weight: bold
    &.unpublished
      margin-top: 10px
    &.truncated
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap

  .sharing-card-date
    position: absolute
    top: 9px
    right: 12px
    font-size: 12px

  .sharing-card-iconinfo
    padding-left: 18px
    position: relative
    margin-bottom: 2px
    >i
      position: absolute
      top: 3px
      left: 0
      font-size: 14px
    p
      font-size: 14px
      small
        display: block
        font-size: 10px

  .sharing-card-replied-outer
    border-radius: 0 0 10px 0
    overflow: hidden
    position: absolute
    width: 6rem
    height: 6rem
    right: 0
    bottom: 0

  .sharing-card-replied-badge
    position: absolute
    right: 0
    bottom: 0
    border-left: solid 6rem transparent

  .sharing-card-replied-text
    position: absolute
    font-weight: bold
    font-size: 14px
    line-height: 1
    bottom: 12px
    color: #fff
    &-solved
      right: 2px
    &-closed
      right: 7px
    &-replied
      right: 12px

  // 自分の発信以外
  &.is-not-mine
    .sharing-card-box
      margin-left: 14px
      order: 2
      &::before
        +triangleL

  // 自分の発信
  &.is-mine
    .sharing-card-head
      padding-right: left
      order: 2
    .sharing-card-box
      margin-right: 14px
      order: 1
      &::before
        +triangleR

  // 支援側設定：ピンク
  &.type-pink
    .sharing-card-box
      background-color: map-get($pink, 'bg')
      +triangleColor(map-get($pink, 'bg'))
      hr
        background-color: map-get($pink, 'hr')
    .sharing-card-cat
      background-color: map-get($pink, 'title')
    .sharing-card-replied-badge
      border-bottom: solid 6rem #c0584e

  // 依頼側設定：グリーン
  &.type-green
    .sharing-card-box
      background: map-get($green, 'bg')
      +triangleColor(map-get($green, 'bg'))
      hr
        background-color: map-get($green, 'hr')
    .sharing-card-cat
      background-color: map-get($green, 'title')
    .sharing-card-replied-badge
      border-bottom: solid 6rem #4f7129

  &.display-in-organization-page
    padding: 1rem
    border: 1px solid #d7d7d7
    border-radius: 0.375rem

.sharing-card.hide-icon
  .sharing-card-head
    display: none
  .sharing-card-box
    margin: 0
    width: 100%
    &::before
      display: none
</style>
