<template>
  <div class="unregistered-message">
    <label class="unregistered-message__label">
      <BIcon
        pack="fal"
        icon="exclamation-triangle"
        size="is-small"
      />
      <span v-text="label" />
    </label>
    <a
      v-if="hasButtonPath"
      :href="buttonPath"
      class="button is-primary is-small is-rounded is-ml-3"
    >
      入力する
    </a>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    buttonPath: {
      type: String,
      default: ''
    }
  },
  computed: {
    hasButtonPath () {
      return this.buttonPath.length > 0
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'
.unregistered-message__label
  color: #b01f24
  font-size: 14px
  font-weight: bold

.button
  display: inline-block
  vertical-align: middle
</style>
